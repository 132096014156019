<template>
  <form @submit.prevent="submit" class="profile-main-form">
    <ProfileAboutInput
      class="profile-main-form__desc"
      v-model="form.about"
      :error="errors.about"
    />
    <ProfileFormActions class="profile-main-form__actions" @cancel="emit('close')" @submit="submit" :class="{ pending: loading }" />
  </form>
</template>

<script setup>
import { storeAboutInfo } from '@/api/user'
import useForm from '~/composables/useForm'
import ProfileFormActions from '@/components/user/profile/forms/ProfileFormActions'
import ProfileAboutInput from '@/components/user/profile/inputs/ProfileAboutInput'

const emit = defineEmits(['close'])

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')
const {
  form,
  errors,
  submit,
  loading
} = useForm(storeAboutInfo, onSuccess)

function onSuccess () {
  updateUserInfo()
  emit('close')
}

watch(user, value => {
  form.value = {
    about: value?.personal.about
  }
}, { immediate: true })
</script>

<style scoped lang="scss">
.profile-main-form {
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  padding: 8px 0 16px;
  &__desc {
    flex: 1;
  }
  &__actions {
    flex-shrink: 0;
    margin-top: 11px;
  }
}
</style>
