<template>
  <div class="profile-main" :class="{ compact }">
    <div class="profile-main__header">
      <h1 class="profile-main__title">{{ data.first_name }} {{ data.last_name }}</h1>
      <div class="profile-main__meta">
        <div class="profile-main__location" v-if="data.city_name">
          <IconMapPin class="icon" />
          <span>{{ data.city_name }}</span>
        </div>
        <div v-if="isNew" class="new-dot" />
      </div>
    </div>
    <slot />
    <StatusNotice v-if="!compact && isOwn && profile.is_mentor && profile.mentor_accepted === false">Ваш профиль находится на проверке у администрации. После подтверждения вы сможете проводить занятия и публиковаться в каталоге менторов.</StatusNotice>
    <div class="profile-main__desc-container" v-if="editable || data.about">
      <template v-if="!editing">
        <IconAlignLeft class="desc-icon" />
        <div class="profile-main__desc" :class="{'placeholder-hint': !data.about}">{{ data.about ?? 'Добавьте краткое описание' }}</div>
        <EditButton class="edit-btn" v-if="editable" @click="editing = true" />
      </template>
      <ProfileMainForm v-else @close="editing = false" />
    </div>
    <div class="profile-main__footer">
      <ProfileAdvantages :user="props.user ?? user" />
    </div>
  </div>
</template>

<script setup>
import { IconMapPin, IconAlignLeft } from '@tabler/icons-vue'
import EditButton from '@/components/user/profile/EditButton'
import ProfileMainForm from '@/components/user/profile/forms/ProfileMainForm'
import ProfileAdvantages from '@/components/user/profile/ProfileAdvantages'
import StatusNotice from '@/components/common/StatusNotice'

const props = defineProps({
  user: Object,
  compact: Boolean,
  isNew: Boolean
})

const user = inject('user')
const editable = inject('profile.editable')
const editing = ref(false)
const data = computed(() => props.user?.personal ?? props.user ?? user.value.personal)

const isOwn = computed(() => !props.user || props.user.id === user.value?.id)
const profile = computed(() => user.value)
</script>

<style lang="scss">
.profile-main {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__title {
    font-weight: 900;
    font-size: 36px;
    line-height: 48px;
    color: var(--color-bg-dark);
    text-transform: uppercase;
    margin: 0;
    display: flex;
    align-items: center;

    a {
      transition: .1s color;
      &:hover {
        opacity: 1;
        color: var(--color-primary);
      }
    }
  }
  &__meta {
    display: flex;
    align-items: center;
    gap: 16px;

    .new-dot {
      margin: 0;
    }
  }
  &__location {
    display: flex;
    align-items: center;
    gap: 8px;
    .icon {
      color: var(--color-elements-tertiary);
      width: 16px;
      height: 16px;
    }
    span {
      color: var(--color-elements-secondary);
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    @media (max-width: 920px) {
      gap: 4px;
      .icon {
        width: 14px;
        height: 14px;
      }
      span {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  &__desc-container {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
    color: var(--color-elements-primary);
    .desc-icon {
      margin-top: 2px;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      color: var(--color-elements-tertiary);
    }
    .desc-icon, .edit-btn {
      flex-shrink: 0;
    }
  }
  &__desc {
    //white-space: pre-wrap;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    &:empty {
      display: none;
    }
  }

  &.compact {
    .profile-main {
      &__title {
        font-weight: 800;
        font-size: 24px;
        line-height: 28px;
        text-transform: none;
      }
    }
  }

  @media (max-width: 920px) {
    &__header {
      flex-direction: column;
      gap: 8px;
    }
    &__title {
      font-size: 16px;
      line-height: 24px;
    }
    &__desc-container {
      .desc-icon, .edit-btn {
        display: none;
      }
    }
    &__footer {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 8px;
    }
    &__visible {
      display: none !important;
    }
    &.compact {
      .profile-main {
        &__title {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
        }
        &__desc-container, &__footer {
          display: none;
        }
      }
    }
  }
}
</style>
