<template>
  <div class="profile-main__advantages" v-if="user.project_count > 0 || user.mentor_lessons >= 10 || user.experience_days >= 365">
    <Chip v-if="user.project_count > 0" not-clickable icon><IconBriefcase class="icon" /><span>Участвовал в <b>{{ user.project_count }}</b> {{ declOfNum(user.project_count, ['проекте', 'проектах', 'проектах']) }} на Платформе</span></Chip>
    <Chip v-if="user.mentor_lessons >= 10" not-clickable icon><IconDeviceLaptop class="icon" /><span>Провел более <b>{{ moreoverRound(user.mentor_lessons) }}</b> занятий</span></Chip>
    <Chip v-if="user.experience_days >= 365" not-clickable icon><IconBriefcase class="icon" /><span>Опыт – {{ experienceYears }}</span></Chip>
  </div>
</template>

<script setup>
import Chip from '@/components/common/Chip'
import { IconDeviceLaptop, IconBriefcase } from '@tabler/icons-vue'

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
})

const experienceYears = computed(() => {
  const years = Math.floor(props.user.experience_days / 365)

  return `${years} ${declOfNum(years, ['год', 'года', 'лет'])}`
})
</script>

<style scoped lang="scss">
.profile-main {
  &__advantages {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}
</style>
